import React from 'react';
import { PathService } from '../../services';
import { IndustriesListView } from '../../views';
import { IndustriesListViewContext, TemplateProps } from '../../parser';

const IndustriesList: React.FunctionComponent<
  TemplateProps<IndustriesListViewContext>
> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return <IndustriesListView list={JSON.parse(pageContext.list)} />;
};

export default IndustriesList;
